import React from 'react';
import {BrowserRouter, Router, Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import AppContext from './AppContext'
import {
    AppBar, Hidden, BottomNavigation, Box, Button, Paper,
    Container, Dialog, MenuList, MenuItem, FormLabel,
    DialogActions, DialogContent,DialogTitle,
    Grid, List, ListItem, Typography, Drawer, ListItemIcon, ListItemText, Divider
} from '@material-ui/core';
import Cart from './components/service/Cart.js'
import Site from './components/service/Site.js'
import Toast from './components/Toast'
import './App.css';
import BottomBar from "./components/BottomBar";
import CartDialog from "./components/CartDialog";
import DeliveryDialog from "./components/DeliveryDialog";
import FoodListMenu from "./components/FoodListMenu";
import LoginDialog from "./components/LoginDialog";
import ProfileDialog from "./components/ProfileDialog";
import MyAppBar from "./components/MyAppBar";
import PaymentDialog from "./components/PaymentDialog";
import PaymentDialog2 from "./components/PaymentDialog2";
import FoodOptionsDialog from "./components/FoodOptionsDialog";
import OfferOptionsDialog from "./components/OfferOptionsDialog";
import InboxIcon from "@material-ui/icons/Inbox";
import MailIcon from "@material-ui/icons/Mail";
import Util from "./components/service/Util";
import LeftDrawer from "./components/LeftDrawer";
import OrderHistoryDialog from "./components/OrderHistoryDialog";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ForgotPassDialog from "./components/ForgotPassDialog";
import MessageBox from "./components/MessageBox";
import WaitingProgress from "./components/WaitingProgress";
import PaymentDialogTest from "./components/PaymentDialogTest";

const themeLight = createMuiTheme({
    type: 'light',
    palette: {
        error: {
            main: "#ff604f"
        }
    }
});
const themeDark = createMuiTheme({
    type: 'dark',

});
const useStyles = makeStyles((theme) => ({
    toolbar: {
        flexGrow: 1,
        color: 'white',
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

class App extends React.Component {
    user = {}
    classes = {};
    constructor(props) {
        super(props);
        AppContext.app = this;
        this.state = {
            fatalError: undefined,
        };
        this.classes = useStyles;
    }

    reload(){
        window.location.reload();
    }

    componentDidMount() {
        Site.instance.checkApiUrls().then(()=>{
            Site.instance.init();
            setTimeout(()=>{
                if (Site.instance.baseApiUrl!==Site.instance.baseApiUrls[0])
                    Site.instance.sendEventLog({data: JSON.stringify({url: Site.instance.baseApiUrl}), event: "apiurlerror"})
            }, 5000)
            Site.instance.fetchSiteData();
            Site.instance.siteSubject.subscribe(()=>{
                Site.instance.addPaypalScript()
                this.setState({site: Site.instance.site})
            })
            Site.instance.fatalErrorSubject.subscribe((e)=>{
                this.setState({fatalError: e});
            })
            Site.instance.checkAuth(Util.instance.hash(""), "", (result, msg)=>{
            }, true)
        })
    }

    render() {
        let site = Site.instance.site;
        let testvar = Util.instance.getQueryVariable("test")
        return (
            <BrowserRouter>
                <MuiThemeProvider theme={site && site.appTheme === 'dark' ? themeDark : themeLight}>
                    {/*<div id="mainContainer" style={{position:"absolute",top:0, left:0, width:"100%", minHeight:280,backgroundSize: "cover",
                            backgroundColor:"#555555"
                        }}></div>*/}
                    <div id="mainContainer" style={{
                        position: "fixed", top: 0, left: 0, width: "100%", height: '100vh', backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundColor: "#555555",
                        zIndex: -9999
                    }}></div>
                    <Container style={{
                        padding: "0",
                        minHeight: "100%",
                        height: "auto !important",
                        margin: "0 auto -142px",
                        maxWidth: '1224px'
                    }}>
                        {
                            this.state.fatalError &&
                            <Dialog open={true}>
                                <DialogTitle>
                                    Error
                                </DialogTitle>
                                <DialogContent>
                                    <Typography component={"h5"} style={{textAlign: "center"}}>
                                        {this.state.fatalError}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="primary" onClick={() => {
                                        this.setState({fatalError: null})
                                    }}>Cancel</Button>
                                    <Button color="primary" onClick={() => {
                                        this.reload();
                                    }}>Reload</Button>

                                </DialogActions>
                            </Dialog>

                        }
                        <MyAppBar/>
                        <FoodListMenu/>

                        <CartDialog/>
                        <LoginDialog/>
                        <ProfileDialog/>
                        <DeliveryDialog/>
                        {testvar === "1" && <PaymentDialog/>}
                         <PaymentDialog2/>
                        <FoodOptionsDialog/>
                        <OfferOptionsDialog/>
                        <OrderHistoryDialog/>
                        <ForgotPassDialog/>
                        <LeftDrawer/>
                        <MessageBox/>
                        <WaitingProgress/>
                        <Toast/>
                        <div style={{height: 142}}></div>
                    </Container>
                    <div style={{
                        height1: 142,
                        minHeight: 142,
                        width: '100%',
                        backgroundColor: "rgb(77 77 77 / 51%)",
                        zIndex: 9999
                    }} className="footer">
                        <Grid item xs={12}
                              style={{maxWidth: '1224px', margin: "0 auto", padding: 15, textAlign: "center"}}>
                            <Typography style={{fontWeight: "bold", color: "white"}}>
                                {Site.instance.site.sitename}
                            </Typography>
                            <div style={{color: "white"}}>{Site.instance.site.address}</div>
                            <Link href="#" to="#" onClick={() => {
                                Site.instance.showMessageBox(Site.instance.site.terms, "Terms & Conditions", "")
                            }} color="white">
                                Terms and Conditions
                            </Link><span style={{margin: 8, color: "white"}}>•</span>
                            <Link href="#" to="#" onClick={() => {
                                Site.instance.showMessageBox(Site.instance.site.aboutus, "About Us", "")
                            }} color="white">
                                About Us
                            </Link>
                            <div style={{color: "white"}} onClick={() => {
                                Site.instance.showMessageBox("Coming soon!", "Message", "")
                            }
                            }>
                                Follow us
                                <FacebookIcon/> <TwitterIcon/> <YouTubeIcon/>
                            </div>

                            <div style={{color: "dimgray", fontSize: "12px"}}>
                                <a href="https://www.ramtd.co.uk" target="_blank">Powered by RAMTD</a>
                            </div>
                            {(!Util.instance.isNative() && Util.instance.isOnAndroid() && Site.instance.site.apkfile) &&
                                <div id="DownloadApp" style={{color: "black", marginTop: 20}}>
                                    <div style={{paddingBottom: 10}}>
                                        <a href={Site.instance.site.apkfile} target="_blank" style={{color: "black"}}>Get
                                            Our Android App</a>
                                    </div>
                                    {Site.instance.site.qrcode &&
                                        <img src={Site.instance.site.qrcode} style={{maxWidth: 120}}/>}
                                </div>
                            }

                        </Grid>
                    </div>
                    <Hidden mdUp>
                        <BottomBar/>
                    </Hidden>
                </MuiThemeProvider>
            </BrowserRouter>
        );
    }
}
export default App;
